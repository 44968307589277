.tag {
    margin-right: 3px;
    border-radius: 12px;
    padding: 6px 12px 5px 12px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    line-height: 1;
    background-color: #6c757d;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.user_tag {
    margin-right: 3px;
    border-radius: 6px;
    padding: 6px 8px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 4px;
    border: 1px solid #3cb5ae;
}

.user_tag .MuiIconButton-root-28 {
    padding: 0px;
    margin-right: 4px;
}

.UserTagsDialogDescription {
    font-size: 1.25em;
    font-weight: 300;
    line-height: 1.5em;
}

.MainLiveVideoTumbnailContainer {
    position: relative;
    width: 100%;
    padding-bottom: 57%;
    margin-bottom: 10px;
}

.MainLiveVideoTumbnailContainer_blank {
    background-color: #cccccc;
    position: relative;
    width: 100%;
    padding-bottom: 57%;
}

.MainLiveVideoTumbnail {
    position: relative;
    width: 100%;
    height: 100;
    display: flex;
    flex-direction: column;
}

.MainLiveVideoTumbnail_Content {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.MainLiveVideoTumbnail_Bottom {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 10px;
    border-bottom: 1px solid #d4d4d4;
}

.MainLiveVideoTumbnail_Bottom_blank {
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;
    width: 100%;
}

.MainLiveVideoTumbnail_BottomTitleBar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-top: 6px;
}

.MainLiveVideoTumbnail_BottomTitle {
    font-size: 1.4em;
    line-height: 1.2em;
    font-weight: 500;
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-align: left;
}

.MainLiveVideoTumbnail_BottomTitle_blank {
    background-color: #e3e3e3;
    font-size: 1.8em;
    line-height: 1.1em;
    font-weight: 500;
    width: 60%;
    display: flex;
    justify-content: space-between;
    height: 30px;
}

.LiveEventReminder {
    position: absolute;
    right: 6px;
    top: 6px;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.80);
    max-width: 420px;
    height: 50px;
    z-index: 1;
}

.LiveEventReminder_Container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 50px;
    padding-right: 12px;
    box-sizing: border-box;
}

.LiveEventReminder_Container_Info {
    display: flex;
    flex-direction: column;
    color: #ffffff;
    align-items: flex-start;
    font-weight: 400;
    line-height: 20px;
    justify-content: center;
    height: 100%;
}

.LiveEventReminder_Button {
    display: flex;
    flex-direction: row;
    background-color: #00a3b4;
    padding: 4px 8px;
    border-radius: 4px;
    color: #FFF;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    height: 26px;
    margin-left: 16px;
    cursor: pointer;
}

.LiveIcon {
    position: absolute;
    width: 30px;
    left: 12px;
    top: 14px;
}

.NotificationIcon {
    margin-left: 6px;
}

.MainLiveVideoTumbnail_BottomDate {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1em;
    font-weight: 500;
    padding: 8px 0px;
}

.MainLiveVideoTumbnail_BottomDescription {
    margin-top: 6px;
    color: #fff;
    text-align: left;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.25em;
    letter-spacing: -0.2px;
}

.MainLiveVideoTumbnail_BottomDescription_blank {
    margin-top: 6px;
    background-color: #e3e3e3;
    text-align: left;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.25em;
    letter-spacing: -0.2px;
    height: 30px;
    width: 40%;
}

.MainLiveVideoTumbnail_Image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.MainLiveVideoTumbnail_ReleaseDatePicker {
    position: absolute;
    right: 40px;
    bottom: 100px;
    padding: 10px;
    background-color: #f4f4f4;
    z-index: 1;
}

.MainLiveVideoTumbnail_Play {
    opacity: .8;
    width: 64px;
}

.AlbumList {
    color: #3C4858;
    width: 100%;
    height: 100%;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #d4d4d4;
}

.AlbumList_Title {
    margin-bottom: 6px;
    width: 100%;
    text-align: left;
    font-size: 22px;
    line-height: 24px;
    font-weight: 500;
    padding: 5px 0px;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.AlbumList_Title_blank {
    margin-top: 16px;
    margin-bottom: 6px;
    margin-left: 5px;
    width: 20%;
    height: 23px;
    background-color: #e3e3e3;
    text-align: left;
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    padding: 5px;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.AlbumList_Videos_1 {
    width: 100%;
    height: 100%;
    padding-bottom: 8px;
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(230px, 1fr));
    grid-gap: 16px;
}

.AlbumList_Videos_2 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    padding-bottom: 8px;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;
}
.AlbumList_Videos_3 {
    width: 100%;
    height: 100%;
    padding-bottom: 8px;
    overflow-y: auto;
    overflow-x: hidden;
}
.AlbumList_VideoThumbnail {
    flex: 1;
    border: 1px solid #000;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.VideoThumbnail {
    width: 230px;
    display: flex;
    padding: 4px;
    margin-bottom: 8px;
    margin-right: 4px;
    display: flex;
    flex-direction: column;
}

.VideoThumbnail_inner {
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}

.VideoThumbnail_Image {
    width: 230px;
    height: 140px;
}

.VideoThumbnail_Play {
    opacity: .8;
    width: 36px;
    position: absolute;
    left: calc(50% - 18px);
    top: calc(25%);
}

.VideoThumbnail_Duration {
    position: absolute;
    right: 4px;
    padding: 0px 4px;
    top: 110px;
    line-height: 1.35em;
    background-color: #2d2d2da8;
    color: #ececec;
    font-size: .75em;
    font-weight: 400;
    text-align: center;
    border-radius: 2px;
}

.VideoThumbnail_Details {
    padding-top: 6px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    line-height: 1.20em;
    font-size: 1em;
    font-weight: 500;
}

.VideoThumbnail_Details_Date {
    width: 100%;
    font-size: .9em;
    font-weight: 300;
    display: flex;
    align-content: center;
    justify-content: space-between;
}

.MainLiveView {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.MainLiveVideoViewContainer {
    width: 100%;
    margin-bottom: 6px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.MainLiveViewVideo {
    width: 100%;
    height: 100%;
    display: flex;
    margin-right: 16px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 101;
    background-color: #FFF;
    position: relative;
}

.MainLiveViewVideo_return {
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 6px;
}

.MainLiveView_Details {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    border-bottom: 1px solid #d4d4d4;
    padding: 4px 8px 16px;
    box-sizing: border-box;
}

.MainLiveView_Chat {
    width: 450px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    border: 1px solid #d4d4d4;
}

.MainLiveView_Tabs {
    width: 470px;
    margin-left: 16px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
}

.MainLiveView_Chat_Flow {
    width: 100%;
    height: 100%;
    padding: 6px;
    background-color: #f9f9f9;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.MainLiveView_Chat_MessageItem {
    width: 100%;
    padding: 8px;
    font-size: 12px;
    box-sizing: border-box;
    line-height: 16px;
    background: #efefef;
    margin-bottom: 3px;
    border-radius: 12px;
}

.MainLiveView_Chat_Avatar {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    position: absolute;
    top: 3px;
    left: 3px;
}

.MainLiveView_Chat_MessageBox {
    width: 100%;
    padding: 6px;
    background-color: #f9f9f9;
    box-sizing: border-box;
    border-top: 1px solid #d4d4d4;
    display: flex;
    flex-direction: column;
    font-size: 14px;
}

.MainLiveView_Chat_MessageBox_Reply {
    display: flex;
    flex-direction: column;
    background-color: #e4e4e4;
    padding: 2px;
    font-size: 13px;
    line-height: 15px;
    position: relative;
}

.ManageLiveView_Followers {
    width: 450px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    border: 1px solid #d4d4d4;
}

.ManageLiveView_Followers_Flow {
    width: 100%;
    height: 100%;
    padding: 6px;
    background-color: #f9f9f9;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.ManageLiveView_Watchers {
    width: 400px;
    margin-left: 16px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    border: 1px solid #d4d4d4;
}

.ManageLiveView_Watchers_Flow {
    width: 100%;
    height: 100%;
    padding: 6px;
    background-color: #f9f9f9;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.MainLiveView_Watcher_Item {
    width: 100%;
    padding: 3px 6px 2px 6px;
    font-size: 12px;
    box-sizing: border-box;
    line-height: 16px;
    display: flex;
    align-items: center;
}

.MainLiveView_Watcher_Item_Indicator {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 6px;
}

.MainLiveView_Comments {
    margin-top: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
}

.MainLiveVideoTumbnail_Description {
    margin-top: 20px;
    width: 100%;
    height: 100%;
    padding: 30px;
    box-sizing: border-box;
    border: 1px solid #d4d4d4;
}

.MainLiveView_Comments_Flow {
    width: 100%;
    height: 100%;
    padding: 6px;
    background-color: #f9f9f9;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.MainLiveView_Comments_Header {
    font-size: 14px;
    font-weight: 600;
}

.MainLiveView_Comments_MessageItem {
    width: 100%;
    padding: 3px 6px 2px 6px;
    font-size: 12px;
    box-sizing: border-box;
    line-height: 16px;
    display: flex;
    flex-direction: column;
}

.MainLiveView_Comments_MessageBox {
    width: 100%;
    padding: 6px 0px;
    background-color: #f9f9f9;
    box-sizing: border-box;
    border-bottom: 1px solid #d4d4d4;
    display: flex;
    flex-direction: column;
    font-size: 14px;
}

.SedondaryLiveVideoTumbnailContainer {
    position: relative;
    width: 100%;
    padding-bottom: 25%;
}

.SedondaryLiveVideoTumbnail {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid #a4a4a4;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-error {
    display: flex;
    justify-content: flex-end;
    font-size: .9em;
    height: 1.1em;
    color: #dd0000;
}

.forgotPassword {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    font-size: .8em;
    padding: 4px 0px;
}

.signInUpSwitch {
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-size: 1em;
    font-weight: 500;
    padding: 10px 0px;
}

.termsWarning {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: .7em;
    font-weight: 300;
    padding: 10px 0px;
    line-height: 1.3em;
    margin-top: 16px;
    margin-bottom: -16px;
}

.Countdown {
    z-index: 1;
    display: flex;
    flex-direction: row;
}

.Countdown-col {
    margin: 0 20px;
    display: flex;
    background-color: #2d2d2da8;
    color: #ececec;
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-top: 26px;
    font-weight: 400;
}

.Countdown-col-number {
    font-size: 4em;
    font-weight: bold;
    margin-bottom: 20px;
}

.SignIn {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SignIn_Header {
    font-size: 1.5em;
    font-weight: 500;
    padding: 24px;
}

.SignIn_Warning {
    font-size: 1em;
    font-weight: 400;
    margin-bottom: 10px;
}

.SignIn_Inner {
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #e1e2e2;
    display: flex;
    flex-direction: column;
}

.SignIn_Inner_Form {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e1e2e2;
}

.SignIn_SocialMedia {
    margin-top: -10px;
    padding: 0px 30px 20px 30px;
    box-sizing: border-box;
}

.VideoUpload {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.VideoUpload_Uploading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.VideoUpload_Uploaded {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
}

.VideoUpload_Header {
    font-size: 1.5em;
    font-weight: 500;
    padding: 24px;
}

.VideoUpload_Inner {
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #e1e2e2;
    display: flex;
    flex-direction: column;
}

.CookieNotify {
    width: 100vw;
    height: 40px;
    position: 'fixed';
    bottom: 0;
    left: 0;
    background: #a4a4a4;
    color: #1e1e1e;
    display: flex;
    flex-direction: row;
}

.UserAgreementForm {
    z-index: 1000;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow-y: hidden;
}

.SurveyForm {
    z-index: 1000;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow-y: hidden;
}

.Payment {
    text-align: left;
    border-radius: 6px;
    background-color: #FFF;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
}

.Payment_Video_Image {
    height: 100%;
    width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.Payment_Details {
    padding: 4px;
}

.Payment_Separator {
    background-color: #dedede;
    width: 2px;
    margin: 0px 4px;
}

.backdrop-blur {
    background-color: rgba(255, 255, 255, .9);
}

.fade-in {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: .3s;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


/* if backdrop support: very transparent and blurred */

@supports ((-webkit-backdrop-filter: blur(6px)) or (backdrop-filter: blur(6px))) {
    .backdrop-blur {
        background-color: rgba(255, 255, 255, .5);
        -webkit-backdrop-filter: blur(6px);
        backdrop-filter: blur(6px);
    }
}

.SurveyForm_inner {
    width: 75%;
    background-color: white;
    padding: 30px;
    margin-top: 100px;
    margin-bottom: 100px;
    border-radius: 6px;
    color: #515151;
    box-shadow: 3px 2px 19px 5px rgba(0, 0, 0, 0.67);
}

.UserAgreementForm_inner {
    max-width: 400px;
    width: 75%;
    background-color: white;
    padding: 30px;
    margin-top: 100px;
    margin-bottom: 100px;
    border-radius: 6px;
    color: #515151;
    box-shadow: 3px 2px 19px 5px rgba(0, 0, 0, 0.67);
}

.UserAgreementForm_title {
    font-size: 1.5em;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 10px;
    line-height: 1.4em;
}

.UserAgreementForm_content {
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 16px;
}

.SearchBar {
    display: flex;
    flex-direction: row;
    width: 100vw;
    justify-content: flex-end;
    padding-right: 100px;
}

.SearchInput {
    width: 300px;
}

.admin-button {
    height: 50px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    background-color: #FFF;
    padding-left: 10px;
    z-index: 10001;
}

.VideoRecommendations {
    width: 350px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    border: 1px solid #d4d4d4;
}
.VideoRecommendations .AlbumList_Title{
    padding-left: 10px;
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 400;
}

/* not hd desktops(1440px and down) */

@media only screen and (max-width: 1980px) {
    .Countdown-col-number {
        margin-bottom: 14px;
    }
    .AlbumList_Videos_1 {
        grid-template-columns: repeat( auto-fit, minmax(270px, 1fr));
    }
    .VideoThumbnail_Duration {
        top: 135px;
    }
    .VideoThumbnail {
        width: 270px;
    }
    .VideoThumbnail_Image {
        width: 270px;
        height: 160px;
    }
}


/* not hd desktops(1440px and down) */

@media only screen and (max-width: 1440px) {
    .Countdown-col-number {
        margin-bottom: 14px;
    }
    .AlbumList_Videos_1 {
        grid-template-columns: repeat( auto-fit, minmax(200px, 1fr));
    }
    .VideoThumbnail_Duration {
        top: 100px;
    }
    .VideoThumbnail {
        width: 200px;
    }
    .VideoThumbnail_Image {
        width: 200px;
        height: 120px;
    }
}

@media only screen and (max-width: 1200px) {
    .Countdown-col-number {
        margin-bottom: 10px;
    }
    .AlbumList_Videos_1 {
        grid-template-columns: repeat( auto-fit, minmax(230px, 1fr));
    }
    .VideoThumbnail_Duration {
        top: 120px;
    }
    .VideoThumbnail {
        width: 230px;
    }
    .VideoThumbnail_Image {
        width: 230px;
        height: 140px;
    }
}

@media only screen and (max-width: 900px) {
    .Countdown-col-number {
        margin-bottom: 10px;
    }
    .AlbumList_Videos_1 {
        grid-template-columns: repeat( auto-fit, minmax(200px, 1fr));
        grid-gap: 6px;
    }
    .VideoThumbnail_Duration {
        top: 100px;
    }
    .VideoThumbnail {
        width: 200px;
    }
    .VideoThumbnail_Image {
        width: 200px;
        height: 120px;
    }
}

@media only screen and (max-width: 600px) {
    .SearchBar {
        padding-right: 62px;
    }
    .SearchInput {
        width: 100%;
    }
    .SearchBar__active {
        background: #FFF;
        padding: 0px;
        z-index: 10001;
        position: fixed;
        left: 0px;
    }
    .Countdown-col-number {
        margin-bottom: 10px;
    }
    .AlbumList_Videos_1 {
        grid-template-columns: repeat( auto-fit, minmax(150px, 1fr));
        grid-gap: 6px;
    }
    .AlbumList_Title {
        font-size: 1.5em;
        line-height: 1.3em;
        margin-bottom: 0px;
    }
    .VideoThumbnail_Duration {
        top: 70px;
    }
    .VideoThumbnail {
        width: 160px;
        margin-bottom: 0px;
    }
    .VideoThumbnail_Image {
        width: 160px;
        height: 86px;
    }
    .VideoThumbnail_Play {
        opacity: .8;
        width: 24px;
        top: calc(22%);
    }
    .MainLiveVideoTumbnail {
        width: 100vw;
    }
    .MainLiveVideoTumbnail_Bottom {
        padding: 0px 8px;
    }
    .MainLiveVideoTumbnail_BottomTitleBar {
        padding-top: 0px;
    }
    .MainLiveVideoTumbnail_BottomDate {
        padding: 2px 0px;
        font-size: 1.1em;
        font-weight: 400;
    }
    .MainLiveVideoTumbnail_BottomTitle {
        margin-bottom: 4px;
        padding: 0;
        font-size: 1.5em;
        line-height: 1.3em;
    }
    .MainLiveView_Details {
        padding-left: 6px;
        box-sizing: border-box;
        padding-bottom: 0px;
    }
    .AlbumList_Title_blank {
        height: 14px;
        width: 80px;
    }
    .MainLiveViewVideo {
        position: fixed;
        top: 76px;
        left: 0;
        height: initial;
    }
    .MainLiveView_Comments {
        overflow: auto;
    }
    .MainLiveView_Comments_Flow {
        overflow: hidden;
        height: 100% !important;
        min-height: 100% !important;
    }
    .LiveEventReminder {
        height: 36px;
        font-size: .8em;
    }
    .LiveEventReminder_Container {
        padding-left: 36px;
        padding-right: 4px;
    }
    .LiveEventReminder_Container_Info {
        line-height: 14px;
    }
    .LiveEventReminder_Button {
        height: 18px;
        margin-left: 12px;
        font-size: 1.1em;
        line-height: 1.1em;
    }
    .LiveIcon {
        width: 20px;
        top: 12px;
        left: 8px;
    }
    .NotificationIcon {
        width: .7em !important;
    }
    .MainLiveVideoTumbnail_Bottom_blank {
        margin-top: 10px;
    }
}