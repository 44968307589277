.Main {
    margin-top: 20px;
    height: 100%;
    width: 60%;
    padding: 20px;
    max-width: 1020px;
}

.ContentBlockerBackground {
    height: 100vh;
    width: 100vw;
    background-color: red;
}

.Main_Video {
    margin-top: 20px;
    height: 100%;
    width: 90%;
    padding: 20px;
}

.Content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: calc(100vh - 60px);
}

.Left {
    height: calc(100vh - 55px);
    flex: 2;
    padding-top: 40px;
    padding-left: 20px;

    /* background: linear-gradient(90deg, #fff 5px, transparent 1%) center, linear-gradient(#fff 5px, transparent 1%) center, #ccc;
  background-size: 10px 10px; */
}

.Right {
    height: calc(100vh - 55px);
    flex: 2;
    padding: 20px;
    /* background: linear-gradient(90deg, #fff 5px, transparent 1%) center, linear-gradient(#fff 5px, transparent 1%) center, #ccc;
  background-size: 10px 10px; */
}

.TermsAndConditions {
    padding: 40px;
    max-width: 800px;
    text-align: left;
}


/* not hd desktops(1440px and down) */

@media only screen and (max-width: 1400px) {
    .Main {
        width: 75%;
    }
}

@media only screen and (max-width: 900px) {
    .Main {
        width: 90%;
    }
}

@media only screen and (max-width: 700px) {
    .Main {
        width: 95%;
        margin-top: 0px;
        padding: 0px 10px 10px 10px;
    }
    .Main_Video {
        width: 100%;
        padding: 0px;
        margin-top: 0px;
    }
    .Content {
        height: calc(100vh - 46px);
    }
}

.Featured {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.FlexRow {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.FlexColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
}