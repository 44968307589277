body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    background-color: #fafafa;
}


/* not hd desktops(1440px and down) */

@media only screen and (max-width: 1400px) {
    body {
        font-size: 14px;
    }
}

@media only screen and (max-width: 1200px) {
    body {
        font-size: 12px;
    }
}

a:link {
    color: #0d0d0d;
}


/* visited link */

a:visited {
    color: #0d0d0d;
}


/* mouse over link */

a:hover {
    color: #0d0d0d;
}


/* selected link */

a:active {
    color: #0d0d0d;
}