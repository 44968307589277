.App {
    text-align: center;
    background-color: #fafafa;
    width: 100vw;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 60px;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}

.App-header {
    position: fixed;
    z-index: 1001;
    top: 0px;
    left: 0px;
    background-color: white;
    width: 100vw;
    height: 60px;
    padding: 3px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: inset -1px -6px 1px -3px rgba(0, 0, 0, 0.06);
}

.App-header-logo {
    position: fixed;
    left: 40px;
}

.App-header-search {
    max-width: 40%;
    width: 100%;
}

.App-header-search input[type="text"] {
    width: 100%;
    height: 32px;
    font-size: 1.1em;
    padding: 0px 10px;
    border-radius: 4px;
    border: 1px solid #d4d4d4;
    color: #444444;
}

.App-header-right {
    position: absolute;
    top: 0px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-right: 40px;
}

.h1 {
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1.4em;
}

.h2 {
    font-size: 1em;
    letter-spacing: -0.5px;
    line-height: 20px;
}

.h3 {
    font-size: 1.1em;
    letter-spacing: -0.1px;
    line-height: 24px;
}

@media only screen and (max-width: 600px) {
    .App {
        padding-top: 45px;
    }
    .App-header {
        height: 40px;
    }
    .App-header-logo {
        left: 10px;
    }
    .App-header-right {
        padding-right: 30px;
    }
}

@media only screen and (max-width: 1300px) {
    .App-header-search {
        max-width: 500px;
        margin-right: 100px;
    }
}